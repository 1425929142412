import React from "react";
import "./index.scss";
import { Contact } from "../components/contact/contact";
import { Me } from "../components/me/me";
import { Order } from "../components/order/order";
import { Home } from "../components/home/home";

import { ThemeProvider } from "@mui/material";
import { theme } from "../utils/theme";

export default class IndexPage extends React.Component {
  render(): React.ReactNode {
    return (
      <ThemeProvider theme={theme}>
        <main className="snap-y overflow-y-scroll snap-mandatory h-screen scroll-smooth">
          <Home />
          <Me />
          <Order />
          <Contact />
        </main>
      </ThemeProvider>
    );
  }
}
