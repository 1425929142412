import React from "react";
import "./home.scss";
import { StaticImage } from "gatsby-plugin-image";
import { Menu } from "../menu/menu";
import Logo from "../../images/svg/masni-logo.svg";
import { TextField, Button } from "@mui/material";

export class Home extends React.Component {
  render(): React.ReactNode {
    return (
      <div className="snap-start relative h-screen flex">
        <StaticImage
          src="../../images/cake.jpg"
          objectFit="cover"
          objectPosition="50% 50%"
          alt="Cake"
          placeholder="blurred"
        />
        <div className="h-screen w-screen absolute flex justify-center flex-col items-center text-6xl font-roboto-bold bg-black/30 text-white">
          <Logo className="logo w-5/6 h-80 sm:h-3/5 fill-primary stroke-primary" />

          <Button href="/cakes/" variant="contained">Sütemények</Button>
        </div>
        <Menu />
      </div>
    );
  }
}
