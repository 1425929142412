import React from "react";
import "./order.scss";
import { TextField, Button } from "@mui/material";
import Cake from "../../images/svg/cake.svg";

export class Order extends React.Component {
  render(): React.ReactNode {
    return (
      <div
        className="snap-start relative h-screen p-8 bg-gray-1 md:grid md:grid-rows-3 md:grid-cols-1 lg:grid-rows-1 lg:grid-cols-2"
        id="order"
      >
        <div className="md:row-span-2 lg:row-span-1">
          <h1 className="block font-roboto-bold text-xl mb-4">Rendelés</h1>
          <p className="mb-4">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cum vitae,
            temporibus vel doloremque tenetur mollitia eligendi harum dolore
            nesciunt minus ex architecto doloribus placeat?
          </p>
          <div className="relative">
            <TextField
              className="block"
              id="outlined-basic"
              label="Név"
              variant="outlined"
              placeholder="Adja meg a nevét!"
              margin="dense"
              fullWidth
            />
            <TextField
              className="block"
              label="Email cím"
              placeholder="Adja meg az email címét!"
              variant="outlined"
              fullWidth
              margin="dense"
            />
            <TextField
              className="block"
              label="Telefonszám"
              placeholder="Adja meg a telefonszámát!"
              variant="outlined"
              fullWidth
              margin="dense"
            />
            <TextField
              label="Leírás"
              placeholder="Adja meg az elkészítendő torta leírást!"
              multiline
              fullWidth
              rows={4}
              margin="dense"
            />
            <div className="text-right">
              <Button variant="contained">Rendelés</Button>
            </div>
            <div className="absolute top-0 left-0 h-full w-full scale-105 flex justify-center items-center text-xl backdrop-blur-[2px] z-10">
              Átmenetileg nem elérhető
            </div>
          </div>
        </div>
        <div className="justify-center items-center hidden md:flex md:order-first h-full lg:order-last">
          <Cake className="h-full lg:w-1/2"/>
        </div>
      </div>
    );
  }
}
