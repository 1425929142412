import React from "react";
import "./contact.scss";
import Map from "../../images/svg/map.svg";
import CallIcon from "@mui/icons-material/Call";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { StaticImage } from "gatsby-plugin-image";

export class Contact extends React.Component {
  render(): React.ReactNode {
    return (
      <div
        className="snap-start h-screen p-8 bg-gray-2 grid grid-rows-6 grid-cols-1 md:grid-cols-2 md:grid-rows-[auto_1fr]"
        id="contact"
      >
        <h1 className="block font-roboto-bold text-xl md:col-span-2">Elérhetőség</h1>
        <div className="row-span-3 md:row-span-1">
          <Map />
        </div>
        <div className="flex p-2 justify-center flex-col items-center row-span-2 md:row-span-1">
          <StaticImage
            src="../../images/me2.JPG"
            alt="Cake"
            placeholder="blurred"
            className="h-48 mb-4 block rounded-full overflow-hidden aspect-square"
          />

          <span className="text-xl pb-3">Szabó-Takács Réka</span>
          <div>
            <CallIcon /> +36 20 3824243
          </div>
          <div>
            <AlternateEmailIcon /> info@masnisuti.hu
          </div>
        </div>
      </div>
    );
  }
}
