import React from "react";
import "./me.scss";
import { StaticImage } from "gatsby-plugin-image";

export class Me extends React.Component {
  render(): React.ReactNode {
    return (
      <div
        className="snap-start relative h-screen bg-primary-bg grid grid-rows-3 grid-cols-1 md:grid-rows-2 lg:grid-rows-1 lg:grid-cols-2"
        id="me"
      >
        <div className="text-center p-8 md:hidden">
          <StaticImage
            src="../../images/me2.JPG"
            alt="Me"
            placeholder="blurred"
            className="h-48 mb-4 rounded-full overflow-hidden aspect-square"
          />
        </div>
        <div className="p-8 row-span-1 md:row-span-1 lg:flex lg:items-center">
          <section>
            <h1 className="text-3xl md:text-5xl ">
              Sziasztok,
              <br />
              Réka vagyok
            </h1>

            <p className="text-base mt-4">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Asperiores accusantium illo labore ipsum nulla, mollitia porro
              voluptatem repellat atque quod ab ipsa vitae quaerat alias quasi
              hic deleniti provident voluptas.
            </p>
          </section>
        </div>

        <div className="hidden p-8 h-full md:flex md:justify-center md:row-span-1 md:order-first lg:order-last lg:p-32">
          <div className="me-profile-picture-container w-60 h-full lg:w-96">
            <StaticImage
              src="../../images/me.JPG"
              alt="Cake"
              placeholder="blurred"
              className="h-full"
            />
            <svg className="w-0 h-0">
              <defs>
                <clipPath id="mask" clipPathUnits="objectBoundingBox">
                  <path d="M0.527,0.028C0.43,-0.058 0.117,0.069 0.086,0.205C0.055,0.34 0.125,0.382 0.028,0.51C-0.069,0.638 0.112,0.805 0.205,0.813C0.298,0.821 0.328,0.89 0.367,0.949C0.407,1.009 0.743,1.094 0.72,0.688C0.697,0.281 0.79,0.328 0.818,0.253C0.846,0.179 0.836,0.114 0.787,0.082C0.706,0.026 0.583,0.078 0.527,0.028Z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    );
  }
}
